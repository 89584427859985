import React, { createContext, useContext, useEffect, useState } from 'react';

const LoadingContext = createContext({
  loading: false,
  startLoading: () => {},
  endLoading: () => {}
});

export const LoadingProvider = ({
  children
}: {
  children: React.ReactNode;
}) => {
  const [loading, setLoading] = useState(false);
  const [reqNum, setReqNum] = useState(0);

  function startLoading() {
    if (reqNum === 0) {
      setLoading(true);
    }
    setReqNum((prevReqNum) => prevReqNum + 1);
  }

  function endLoading() {
    setTimeout(closeLoading, 300);
  }

  function closeLoading() {
    setReqNum((prevReqNum) => Math.max(prevReqNum - 1, 0));
  }

  useEffect(() => {
    if (reqNum === 0) {
      setLoading(false);
    }
  }, [reqNum]);

  return (
    <LoadingContext.Provider value={{ loading, startLoading, endLoading }}>
      {children}
    </LoadingContext.Provider>
  );
};

export const useLoadingContext = () => {
  return useContext(LoadingContext);
};
