type ConfigType = Record<
  string,
  {
    apiPath: string;
    isH5: boolean;
    i18nPath: string;
    [propName: string]: any;
  }
>;

const reactAppMockToken = '';

const config: ConfigType = {
  local: {
    apiPath: 'http://192.168.192.157:22661',
    isH5: (window as any).ReactNativeWebView == null,
    reactAppMockToken,
    i18nPath: '/i18nApiPath/i18n_dir/smok_ground_promotion_h5/locales',
    qiniu: {
      // 区域
      region: 'na0',
      // 域名
      domain: 'https://res.smoktech.com',
      // 前缀
      prefix: 'ground_dev/v1'
    }
  },
  test: {
    apiPath: 'http://192.168.192.157:22661',
    isH5: (window as any).ReactNativeWebView == null,
    reactAppMockToken,
    i18nPath: 'http://192.168.192.157:22691/i18n_dir/smok_ground_promotion_h5/locales',
    qiniu: {
      // 区域
      region: 'na0',
      // 域名
      domain: 'https://res.smoktech.com',
      // 前缀
      prefix: 'ground_dev/v1'
    }
  },
  prod: {
    apiPath: 'https://ground.smoktech.com',
    isH5: (window as any).ReactNativeWebView == null,
    i18nPath: 'https://admin.ground.smoktech.com/i18n_dir/smok_ground_promotion_h5/locales',
    qiniu: {
      // 区域
      region: 'na0',
      // 域名
      domain: 'https://res.smoktech.com',
      // 前缀
      prefix: 'ground/v1'
    }
  }
};

const type = process.env.REACT_APP_TYPE ?? 'local';

export default config[type];
