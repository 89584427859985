import React from 'react';
import ReactDOM from 'react-dom/client';
import '@/utils/postJsCode';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistor, store } from '@/store';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './assets/style/public.less';
import '@/lang';
import i18n, { updateLanguage } from './lang';
import VConsole from 'vconsole';
import { LoadingProvider } from '@/utils/loading';
import enUS from 'antd-mobile/es/locales/en-US';
import { ConfigProvider } from 'antd-mobile';
import baseConfig from '@/config';

if (process.env.NODE_ENV === 'development' && !baseConfig.isH5) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const vConsole = new VConsole();
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

function render(): void {
  root.render(
    <ReduxProvider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <React.StrictMode>
          <App />
        </React.StrictMode> */}
        <LoadingProvider>
          <ConfigProvider locale={enUS}>
            <App />
          </ConfigProvider>
        </LoadingProvider>
      </PersistGate>
    </ReduxProvider>
  );
}

// Listen for the custom event and render the app when it's fired
window.addEventListener('i18nInitialized', () => {
  render();
});

store.subscribe(() => {
  updateLanguage();
  if (i18n.isInitialized) {
    render();
  }
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
void reportWebVitals();
