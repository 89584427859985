import React, { type FC, Suspense, useEffect, lazy } from 'react';
import '@/App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@/store/redux-hooks';
import { selectUserInfo, setUserInfo } from '@/store/slicers/userSlice';
import Loading from '@/pages/public/loading';
import baseConfig from '@/config';

const CheckIn = lazy(async () => await import('@/pages/checkIn/index'));
const AddShop = lazy(async () => await import('@/pages/shop/add'));
const ShopDetail = lazy(async () => await import('@/pages/shop/detail'));
const CheckInRecordList = lazy(
  async () => await import('@/pages/checkInRecord/list')
);
const ShopList = lazy(async () => await import('@/pages/shop/list'));

const fakeAuth = async () => {
  return await new Promise((resolve) => {
    if (baseConfig.isH5) {
      resolve({
        access_token: baseConfig.reactAppMockToken
      });
    } else {
      (window as any).APP.invokeClientMethod(
        'getUserInfo',
        undefined,
        (res: any) => {
          resolve(res);
        }
      );
    }
  });
};

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const userInfo = useAppSelector(selectUserInfo);
  const { access_token: token } = userInfo;
  const dispatch = useAppDispatch();
  useEffect(() => {
    fakeAuth()
      .then((res) => {
        dispatch(setUserInfo(res));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  if (token == null) {
    return <Loading />;
  }

  return <>{children}</>;
};

const App: FC = () => {
  // https://stackoverflow.com/questions/70368760/react-uncaught-referenceerror-process-is-not-defined/71083312#71083312
  // 解决process未定义问题

  useEffect(() => {
    window.process = {
      ...window.process
    };
  }, []);

  return (
    <Router>
      <Suspense fallback={<Loading />}>
        <Routes>
          <Route path="/check-in">
            <Route
              path=":storeId"
              element={
                <ProtectedRoute>
                  <CheckIn />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/check-in/record/list"
            element={
              <ProtectedRoute>
                <CheckInRecordList />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/check-in/record/detail">
            <Route
              path=":recordId"
              element={
                <ProtectedRoute>
                  <CheckIn />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/shop/list"
            element={
              <ProtectedRoute>
                <ShopList />
              </ProtectedRoute>
            }
          ></Route>
          <Route
            path="/shop/add"
            element={
              <ProtectedRoute>
                <AddShop />
              </ProtectedRoute>
            }
          ></Route>
          <Route path="/shop/detail">
            <Route
              path=":storeId"
              element={
                <ProtectedRoute>
                  <ShopDetail />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/shop/edit">
            <Route
              path=":storeId"
              element={
                <ProtectedRoute>
                  <ShopDetail />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route path="/shop/record-detail">
            <Route
              path=":storeId"
              element={
                <ProtectedRoute>
                  <ShopDetail />
                </ProtectedRoute>
              }
            />
          </Route>
          <Route
            path="/questionnaire/preview/:questionnaireId"
            element={<CheckIn />}
          ></Route>
        </Routes>
      </Suspense>
    </Router>
  );
};

export default App;
