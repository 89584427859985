/***
 * postJsCode.js
 * 预注入webview javascript code
 * web端使用：
 * window.APP.invokeClientMethod('getList', { page: 1 , size: 10}, callback);
 * * */
function clientMethod () {
  const APP = {
    __GLOBAL_FUNC_INDEX__: 0,
    invokeClientMethod: function (type, params, callback) {
      if (window.ReactNativeWebView == null) return;
      let callbackName;
      if (typeof callback === 'function') {
        callbackName = '__CALLBACK__' + APP.__GLOBAL_FUNC_INDEX__++;
        APP[callbackName] = callback;
      }
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ type, params, callback: callbackName })
      );
    },
    invokeWebMethod: function (callback, res) {
      if (typeof callback === 'string') {
        const func = APP[callback];
        if (typeof func === 'function') {
          setTimeout(function () {
            func.call(this, res);
          }, 0);
        }
      }
    }
  };
  window.APP = APP;
  window.webviewCallback = function (data) {
    window.APP.invokeWebMethod(data.callback, data.res);
  };
}

clientMethod();
