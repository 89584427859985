import React from 'react';
import { SpinLoading } from 'antd-mobile';
import styles from './loading.module.less';

function Loading() {
  return (
    <div className={styles.container}>
      <SpinLoading color="primary" />
    </div>
  );
}
export default Loading;
